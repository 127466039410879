<template>
  <div class="fill-height">
    <template>
      <AuthWindow @afterLogin="afterLogin"/>
    </template>
  </div>
</template>

<script>
import AuthWindow from "@/components/Auth/AuthWindow";
import {mapGetters} from "vuex";

export default {
  components: {
    AuthWindow
  },
  computed: {
    ...mapGetters({
      'location': 'getLocation',
    })
  },
  methods: {
    afterLogin() {
      if (this.location == 'turkey') {
        this.$emitEvent('notify', {
          color: 'green',
          title: 'در حال انتقال به درگاه بانکی هستید.',
          id: 'success_turkish_campaign'
        });
        setTimeout(() => {
          window.location = 'https://smiaroom.myshopify.com/cart/45734645072188:1?channel=buy_button'
        }, 1000)
      } else {
        this.$emitEvent('notify', {
          color: 'red',
          title: 'شما ساکن ترکیه نیستید. در حال انتقال به سبد خرید هستید.',
          id: 'success_turkish_campaign'
        });
        setTimeout(() => {
          this.$router.push({
            name: "preorder",
            params: {orderId: '68', lang: 'fa'}
          })
        }, 1000)
      }
    },
  },
  mounted() {
    if (this.isLoggedin && this.location) {
      this.afterLogin()
    }
  },
  watch: {
    'location'() {
      if (this.isLoggedin) {
        this.afterLogin()
      }
    }
  }
}
</script>

<style scoped>

</style>