<template>
  <div>
    <div class="text-center mt-8">
      <div class="loginBox   mt-5 ">
        <v-window v-model="authWindow" touchless>
          <v-window-item value="login">
            <div class="authForm" id="login">
              <div class="text-center titleLogin mb-8">
                {{ $t('loginComponent.login') }}
              </div>
              <img src="../../assets/image/webp/login/avatar.webp" alt="">
              <v-form class="mt-5 mx-8 " ref="loginForm"
                      @keypress.native.enter="needToResetEmail ? resetEmail() : login_methods.password ? login() : getLoginMethod()">
                <!--                <InputWrapper modelkey="email" :model="loginForm" append-icon="mdi-email"-->
                <!--                              :rules="emailRules"-->
                <!--                              style="direction: rtl !important;"-->
                <!--                              id-input="email"-->
                <!--                              ref="firstFieldRef"-->
                <!--                              @keydown.enter.prevent="$refs.secondFieldRef.focus()"-->
                <!--                              :tab_index_number="1"-->
                <!--                              :placeholder="$t('emailLabel')"/>-->

                <v-text-field
                    v-model="loginForm.email"
                    outlined
                    prepend-inner-icon="mdi-email"
                    style="font-family: en-light !important;border-radius: 8px!important;"
                    :rules="emailRules"
                    ref="firstFieldRef"
                    @keydown.enter.prevent="$refs.secondFieldRef.focus()"
                    :tab_index_number="1"
                    :label="$t('emailLabel')"
                    id="email"

                ></v-text-field>
                <v-fade-transition>
                  <v-text-field
                      outlined
                      style="border-radius: 8px!important;"
                      v-if="login_methods.password"
                      @click:append="changePassType(loginForm)"
                      v-model="loginForm.password"
                      :type="loginForm.passwordType"
                      id="password"
                      append-icon="mdi-eye"
                      prepend-inner-icon="mdi-lock"
                      :tab_index_number="2"
                      ref="secondFieldRef"
                      :label="$t('passwordLabel')"
                  ></v-text-field>
                </v-fade-transition>
                <v-btn color="#46b1a1" class=" loginBtn " elevation="0"
                       @click="login_methods.password ? login() : getLoginMethod()"
                       :tab_index_number="3"
                       id="loginBtn"
                       :class="!needToResetEmail && 'white--text'"
                       :text="needToResetEmail"
                       :disabled="loadingStatus"
                       v-if="!needToResetEmail"
                       style="position: relative;z-index: 10">
                  <template>
                    {{ login_methods.password ? 'ورود' : 'ادامه' }}
                  </template>
                  <!--                  {{ $t('loginComponent.login') }}-->
                </v-btn>

                <p class="mt-2" style="color: grey;font-size: 15px"
                   v-if="!needToResetEmail && Object.keys(login_methods).length != 0"
                >
                  یا
                </p>
                <div class="loginMethod mt-2">
                  <div class="loginItem" v-if="login_methods.whatsapp" @click="sendWhatsappOtp">
                    <div class="titleItem flex-grow-1 font-weight-bold">ورود با واتس‌اپ</div>
                    <div class="iconItem  fill-height">
                      <img src="../../assets/whatsapp.png" width="30px" alt="">
                    </div>
                  </div>
                  <div class="loginItem" @click="loginBy('google')" v-if="platFormInfoLS?.id != 4">
                    <div class="titleItem flex-grow-1 font-weight-bold">ورود با گوگل</div>
                    <div class="iconItem  fill-height">
                      <img src="../../assets/matchMaking/google.svg" width="30px" alt="">
                    </div>
                  </div>
                </div>

                <v-btn color="#46b1a1" class=" loginBtn " elevation="0"
                       @click="resetEmail "
                       :tab_index_number="3"
                       id=""
                       :class="!needToResetEmail && 'white--text'"
                       :text="needToResetEmail"
                       :disabled="loadingStatus"
                       v-if="needToResetEmail"
                       style="position: relative;z-index: 10">
                  <template>
                    تغییر ایمیل
                  </template>
                  <!--                  {{ $t('loginComponent.login') }}-->
                </v-btn>

                <v-btn class="mt-5 mb-2" style="color: #6d6d6d;z-index: 9999"
                       :to="{name:'onboarding',params:{lang:'fa'}}" text
                       id="registerBtn">
                  {{ $t('loginComponent.noAccount') }}
                  <span style="color: #46b1a1" class="font-weight-bold">
                  {{ $t('register') }}
                </span>
                </v-btn>
                <v-btn v-if="login_methods.password" style="position: relative;z-index: 10" color="#46b1a1" text
                       @click="authWindow = 'forgetpassword'" id="forgetPasswordBtn">
                  {{ $t('loginComponent.forgetPassword.title') }}
                </v-btn>
              </v-form>
            </div>
          </v-window-item>
          <!--          <v-window-item value="register">-->
          <!--            <div class="authForm text-center" id="register">-->
          <!--              <div class="titleAuthWindow">-->
          <!--                {{ $t('register') }}-->
          <!--              </div>-->
          <!--              <v-form class="mt-12 mx-8" ref="registerForm" @keypress.native.enter="register">-->

          <!--                <v-text-field-->
          <!--                    v-model="registerFrom.email"-->
          <!--                    prepend-inner-icon="mdi-email"-->
          <!--                    :rules="emailRules"-->
          <!--                    ref="firstFieldRef"-->
          <!--                    @keydown.enter.prevent="$refs.secondFieldRef.focus()"-->
          <!--                    :tab_index_number="1"-->
          <!--                    :label="$t('emailLabel')"-->
          <!--                    id-input="emailRegister"-->
          <!--                ></v-text-field>-->
          <!--                <div class="ltrInput">-->
          <!--                  <v-text-field-->
          <!--                      :loader-height="0"-->
          <!--                      style="font-size: 1em"-->
          <!--                      dir="rtl"-->
          <!--                      type="tel"-->
          <!--                      id="phoneRegister"-->
          <!--                      prepend-inner-icon="mdi-cellphone"-->
          <!--                      name="tel"-->
          <!--                      placeholder="+1 555 123 4567"-->
          <!--                      v-model="phone"-->
          <!--                      :rules="phoneRules"-->
          <!--                      :label="$t('signupComponent.phone')"-->
          <!--                  >-->
          <!--                  </v-text-field>-->
          <!--                </div>-->
          <!--                &lt;!&ndash;                <v-text-field&ndash;&gt;-->
          <!--                &lt;!&ndash;                    :rules="passwordRules"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    :type="registerFrom.passwordType"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    v-model="registerFrom.password"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    append-outer-icon="mdi-eye"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    prepend-inner-icon="mdi-lock"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    lable="'sdvsdvdsv'"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    tabindex="2"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    @click:prepend-inner="changePassType"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    id="passwordRegister"&ndash;&gt;-->
          <!--                &lt;!&ndash;                ></v-text-field>&ndash;&gt;-->
          <!--                &lt;!&ndash;                <InputWrapper&ndash;&gt;-->
          <!--                &lt;!&ndash;                    modelkey="password"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    :model="registerFrom" @appendOuterIcon="changePassType($event)"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    :type="registerFrom.passwordType"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    id-input="password"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    tabindex="2"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    append_outer_icon="mdi-eye"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    append-icon="mdi-lock"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    :rules="requiredRules"&ndash;&gt;-->
          <!--                &lt;!&ndash;                    :label="$t('passwordLabel')"/>&ndash;&gt;-->
          <!--                <v-text-field-->
          <!--                    @click:append="changePassType(registerFrom)"-->
          <!--                    v-model="registerFrom.password"-->
          <!--                    :type="registerFrom.passwordType"-->
          <!--                    id-input="password"-->
          <!--                    append-icon="mdi-eye"-->
          <!--                    prepend-inner-icon="mdi-lock"-->
          <!--                    :tab_index_number="2"-->
          <!--                    ref="secondFieldRef"-->
          <!--                    :rules="requiredRules"-->
          <!--                    :label="$t('passwordLabel')"-->
          <!--                ></v-text-field>-->
          <!--                &lt;!&ndash;                <InputWrapper modelkey="password"&ndash;&gt;-->
          <!--                &lt;!&ndash;                              :model="registerFrom"&ndash;&gt;-->
          <!--                &lt;!&ndash;                              @appendOuterIcon="changePassType"&ndash;&gt;-->
          <!--                &lt;!&ndash;                              :type="registerFrom.passwordType"&ndash;&gt;-->
          <!--                &lt;!&ndash;                              id-input="passwordRegister"&ndash;&gt;-->
          <!--                &lt;!&ndash;                              tabindex="2"&ndash;&gt;-->
          <!--                &lt;!&ndash;                              append-icon="mdi-lock"&ndash;&gt;-->
          <!--                &lt;!&ndash;                              append_outer_icon="mdi-eye"&ndash;&gt;-->
          <!--                &lt;!&ndash;                              :rules="passwordRules" prepend_inner_icon="mdi-eye" />&ndash;&gt;-->

          <!--                <v-btn color="#46b1a1" class="white&#45;&#45;text loginBtn mt-5" elevation="0" @click="register"-->
          <!--                       tabindex="3"-->
          <!--                       id="registerSubmit"-->
          <!--                       :disabled="loadingStatus"-->
          <!--                       style="position: relative;z-index: 10">-->
          <!--                  {{ $t('register') }}-->
          <!--                </v-btn>-->
          <!--                <v-btn class="googleBtn mt-4" elevation="0" @click="loginBy('google')" id="googleBtn">-->
          <!--                  <img src="../../assets/matchMaking/google.svg" alt="">-->
          <!--                  {{ $t('matchMaking.registerForm.registerByGoogle') }}-->
          <!--                </v-btn>-->

          <!--                <v-btn class="mt-10 mb-2" id="loginChangePage" style="color: #6d6d6d;z-index: 9999"-->
          <!--                       @click="authWindow = 'login'" text>-->
          <!--                  {{ $t('loginComponent.haveAccount') }}-->
          <!--                  <span style="color: #46b1a1" class="font-weight-bold">-->
          <!--                   {{ $t('loginComponent.login') }}-->
          <!--                </span>-->
          <!--                </v-btn>-->
          <!--              </v-form>-->
          <!--            </div>-->
          <!--          </v-window-item>-->
          <v-window-item value="forgetpassword">
            <div class="authForm" id="forgetpassword">
              <div class="titleAuthWindow">
                {{ $t('loginComponent.forgetPassword.title') }}
              </div>
              <p class="descriptionAuthWindow mt-5 px-10">
                <!--                {{ $t('loginComponent.forgetPassword.subTitle') }}-->
              </p>
              <v-form class="mt-5 mx-8" ref="forgetPassword" @keypress.native.enter="resetPassword">

                <v-text-field
                    v-model="forgetPassword.email"
                    prepend-inner-icon="mdi-email"
                    :rules="emailRules"
                    ref="firstFieldRef"
                    @keydown.enter.prevent="$refs.secondFieldRef.focus()"
                    :tab_index_number="1"
                    :label="$t('emailLabel')"
                    id="emailForget"
                ></v-text-field>

                <v-btn color="#46b1a1" class="white--text loginBtn mt-5" elevation="0" @click="resetPassword"
                       tabindex="3"
                       id="ForgetPasswordAction"
                       :disabled="loadingStatus"
                       style="position: relative;z-index: 10">
                  {{ $t('loginComponent.forgetPassword.action') }}
                </v-btn>
                <v-btn :disabled="loadingStatus" color="#46b1a1" id="returnBtn" text class="mt-4"
                       @click="authWindow = 'login'"
                       style="z-index: 10">
                  {{ $t('return') }}
                </v-btn>
              </v-form>

            </div>
          </v-window-item>
          <v-window-item value="whatsapp-otp">
            <img src="../../assets/logo/Logo.svg" class="mt-2" alt="">
            <h3>
              کد تایید را وارد کنید
            </h3>
            <p class="mt-2">
              کد تایید برای شما در
              <b class="font-weight-bold">
                واتساپ
              </b>
              ارسال شد.
            </p>
            <v-form @submit.prevent="submitWhatsappOtp">
              <div style="width: 70%;margin: 0px auto">
                <v-otp-input
                    plain
                    type="number"
                    class="my-10"
                    @finish="submitWhatsappOtp"
                    style="direction: ltr;border-color: red"
                    v-model="whatsappOtpCode"
                    length="6"
                ></v-otp-input>
              </div>
              <v-btn color="#46b1a1" class="white--text loginBtn mt-5" elevation="0"
                     @click="submitWhatsappOtp"
                     :tab_index_number="3"
                     :disabled="loadingStatus"
                     style="position: relative;z-index: 10">
                ورود
                <!--                  {{ $t('loginComponent.login') }}-->
              </v-btn>
            </v-form>
            <v-btn
                :disabled="otpTimer != 0"
                text
                class="mt-2"
                style="z-index: 10"
                color="#46b1a1"
                @click="resendWhatsappOtp"
            >
              ارسال مجدد کد
            </v-btn>
            <br>
            {{ otpTimer / 60 | minFilter }}:{{ otpTimer % 60 | secFilter }}

          </v-window-item>
        </v-window>
        <img src="../../assets/image/png/login/back.png" class="bottomBack" alt="">
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import InputWrapper from "@/components/Main/Wrapper/InputWrapper";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import AuthMixins from "@/mixins/AuthMixins";
import EventsMixins from "@/mixins/EventsMixins";

/**
 * <h1>Login Form</h1>  <p>this component contain <b>Login & register & forgot password</b> functionality</p>
 *
 * @vue-data {Object} [loginForm] - hold login form data
 * @vue-data {Object} [registerFrom] - hold register form data
 * @vue-data {Object} [forgetPassword] - hold forgot password form data
 * @vue-event  Mounted - check if action query exists in url , render appropriate window
 */

export default {
  components: {InputWrapper},
  mixins: [AuthMixins, EventsMixins],
  data() {
    return {
      whatsappOtpCode: null,
      loginForm: {
        passwordType: 'password',
        email: null,
        password: null,
      },
      registerFrom: {
        passwordType: 'password',
        email: null,
        password: null,
      },
      forgetPassword: {
        email: null,
      },
      // authWindow: "whatsapp-otp",
      authWindow: "login",
      phone: null,
      login_methods: {},
      otpTimer: 300,
      timer: null,
      needToResetEmail: false,
    }
  },
  filters: {
    secFilter(val) {
      let sec = Number(val).toFixed(0)
      if (sec < 10) {
        return '0' + sec
      }
      return sec
    },
    minFilter(val) {
      let min = Math.floor(val);
      if (min < 10) {
        return '0' + min
      }
      return min
    }
  },
  mounted() {
    if (this.$route.query.action) {
      this.authWindow = this.$route.query.action
    }

  },
  methods: {
    resetEmail() {
      this.loginForm.email = null
      this.needToResetEmail = false
      this.login_methods = {}
    },
    loginBy(target) {
      switch (target) {
        case 'google':
          window.location.href = 'https://api.simiaroom.com/api/v1/auth/social/google';
      }
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.otpTimer != 0) {
          this.otpTimer -= 1
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    resetTimer() {
      clearInterval(this.timer)
      this.otpTimer = 300
    },
    resendWhatsappOtp() {
      this.resetTimer()
      this.sendWhatsappOtp()
    },
    submitWhatsappOtp() {
      if (this.whatsappOtpCode) {
        this.$emitEvent('setLoading', true)
        this.requesting('auth', 'submit-whatsapp-otp', {}, {
          email: this.loginForm.email,
          otp: this.whatsappOtpCode,
        }).then((resp) => {
          this.$emitEvent('notify', {color: 'green', title: 'خوش‌ آمدید', id: 'success_login_whatsapp'});
          setTimeout(() => {
            this.$router.push({name: 'LoginWithToken', query: {token: resp.data.token, callback: 'dashboard'}})
          }, 1000)
        }).finally(() => {
          this.$emitEvent('setLoading', false)
        })
      } else {
        this.$emitEvent('notify', {color: 'red', title: 'لطفا کد را وارد کنید', id: 'failed_login_whatsapp'});
      }
    },
    sendWhatsappOtp() {
      if (this.$refs.loginForm.validate()) {
        this.$emitEvent('setLoading', true)
        this.requesting('auth', 'send-whatsapp-otp', {}, {email: this.loginForm.email}).then((resp) => {
          this.authWindow = 'whatsapp-otp'
          this.startTimer()
        }).finally(() => {
          this.$emitEvent('setLoading', false)
        })
      }
    },
    getLoginMethod() {
      if (this.$refs.loginForm.validate()) {
        this.$emitEvent('setLoading', true)
        this.requesting('auth', 'login-methods', {}, {email: this.loginForm.email}).then((resp) => {
          this.login_methods = resp.data.login_methods
          this.needToResetEmail = !resp.data.login_methods.password
        }).finally(() => {
          this.$emitEvent('setLoading', false)
        })
      }
    },
    /**
     *
     * This is Function for change password input type in login or register form, you must declare which form you want to change password input
     *
     * @param {string} model window value of target form
     *
     * */
    changePassType(model) {
      if (model.passwordType == 'password') {
        model.passwordType = 'text'
      } else {
        model.passwordType = 'password'
      }
    },
    /**
     *
     * This is Function for login user in platform. the login process includes the following steps:
     *
     * <br/>
     *
     * <b> 1- validate login form </b><br>
     * <b> 2- send request to /api/v1/login with email and password </b><br>
     * <b> 3- if email and password are correct, send request to /api/v1/profile to get profile of user and store profile and jwt token in localStorage</b><br>
     * <b> 4- notify user with id <i>ga_login</i> and emit <i>afterLogin</i></b><br>
     *
     * */
    login() {

      if (this.$refs.loginForm.validate()) {
        this.$emitEvent('setLoading', true)
        this.requesting('auth', 'login', '', {
          email: this.loginForm.email,
          password: this.loginForm.password
        }).then(() => {
          this.requesting('auth', 'getProfile').then(() => {
            this.WebengageSetUserLoggedIn(this.$store.getters.getProfile.id)
            this.$emitEvent('setLoading', false)
            this.$emitEvent('notify', {color: 'green', title: this.$t('Notify.loginSuccess'), id: 'ga_login'});
            // setTimeout(() => {
            this.$emit('afterLogin')
            // }, 2000)
          })
        }).catch(() => {
          this.$emitEvent('setLoading', false)
        })
      }
    },

    /**
     *
     * This is Function for register user in platform. the register process includes the following steps:
     *
     * <br/>
     *
     * <b> 1- validate register form </b><br>
     * <b> 2- send request to /api/v1/register with email , password , re-password , mobile </b><br>
     * <b> 3- If the registration was done without problems, send request to /api/v1/login with email and password to get jwt token</b><br>
     * <b> 4- with token get user profile, send request to /api/v1/profile and store profile in localStorage</b><br>
     * <b> 5- notify user with id <i>ga_signup</i> and emit <i>afterLogin</i></b><br>
     *
     * */
    register() {
      if (this.$refs.registerForm.validate()) {
        let phone = this.phone.replaceAll(' ', '');
        const phoneNumber = parsePhoneNumberFromString(phone);
        if (phoneNumber) {
          if (!phoneNumber.isValid()) {
            this.$emitEvent('notify', 'red', undefined, this.$t('Notify.signupComponent.phoneNumberError'));
            return;
          }
        } else {
          this.$emitEvent('notify', 'red', undefined, this.$t('Notify.signupComponent.phoneNumberError'));
          return;
        }
        this.$emitEvent('setLoading', true)
        let newUser = {
          email: this.registerFrom.email,
          password: this.registerFrom.password,
          password_confirmation: this.registerFrom.password,
          mobile: phoneNumber.number,
        };
        this.requesting('auth', 'register', '', newUser).then(() => {
          this.requesting('auth', 'login', '', {
            email: this.registerFrom.email,
            password: this.registerFrom.password
          }).then(() => {
            this.requesting('auth', 'getProfile').then(() => {

              this.$emitEvent('notify', {
                color: 'green',
                title: this.$t('Notify.signupComponent.success'),
                id: "ga_signup"
              });

              this.WebengageUserRegister(this.$store.getters.getProfile.id, new Date(), this.registerFrom.email, phoneNumber.number)

            }).finally(() => {
              this.$emitEvent('setLoading', false)
              this.$emit('afterLogin')
            })
          })
        }).catch(() => {
          this.$emitEvent('setLoading', false)
        })
      }
    },


    /**
     *
     * This is Function for send forget password request in platform. the forget password process includes the following steps:
     *
     * <br/>
     *
     * <b> 1- validate forget password form </b><br>
     * <b> 2- send request to /api/v1/forgotPassword with email</b><br>
     * <b> 3- notify user with id <i>emailNotify</i></b><br>
     * <b> 4- return to login form ></b><br>
     *
     * */
    resetPassword() {
      if (this.$refs.forgetPassword.validate()) {
        this.$emitEvent('setLoading', true)
        this.requesting('auth', 'forgetPassword', '', {
          email: this.forgetPassword.email
        }).then(() => {
          this.$emitEvent('notify', {color: 'green', title: this.$t('sentEmailLink'), id: 'emailNotify'})
          setTimeout(() => {
            this.authWindow = 'login'
          }, 1000)
        }).finally(() => {
          this.$emitEvent('setLoading', false)
        })
      }
      // forgetPassword
    }
  },
}

</script>

<style>
@media (min-width: 200px ) and (max-width: 600px) {
  .loginBox {
    width: 90% !important;
    margin: 10px auto !important;
  }
}

.loginMethod {
  /*margin-top: 20px;*/
}

.loginItem {
  display: flex;
  cursor: pointer;
  border: 1px solid #ededed;
  /*padding: 5px;*/
  /*padding-top: 10px;*/
  border-radius: 10px;
  margin-bottom: 20px;
  /*box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;*/
  box-shadow: 0 3px 5px -3px rgba(0, 0, 0, .3);
  transition: all 0.3s;
}

.loginItem:hover {
  /*border-color: #3cbe8b;*/
  transition: all 0.3s;
  box-shadow: 0 5px 15px -7px rgba(0, 0, 0, .3);
}

.iconItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 5px;
}

.titleItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 5px;
  font-size: 12px;
  margin-right: 30px;
}

.googleBtn {
  width: 100% !important;
}

.loginBox .v-input input {
  font-family: en-light !important;
}

.loginBox {
  width: 414px;
  padding: 25px;
  margin: 0px auto;
  border-radius: 15px;
  box-shadow: 0 3px 17px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #fbfbfb;
  background-color: #fff;
  position: relative;
}

.bottomBack {
  position: absolute;
  bottom: 0px;
  width: 100%;
  right: 0px;
}

.loginBtn {
  width: 100%;
  height: 50px !important;
  font-size: 17px;
  font-weight: bolder;
  border-radius: 10px;
}

.titleAuthWindow {
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0, 0, 0);
}

.authForm {
  z-index: 999;
}

.descriptionAuthWindow {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: rgb(109, 109, 109);
}
</style>