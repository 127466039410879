import { render, staticRenderFns } from "./TurkishCamaign.vue?vue&type=template&id=39512afe&scoped=true&"
import script from "./TurkishCamaign.vue?vue&type=script&lang=js&"
export * from "./TurkishCamaign.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39512afe",
  null
  
)

export default component.exports