<template>
  <div class="loginArea d-flex flex-column">
    <div class="headerBanner">
      <img src="../../assets/logo/logoWhite.svg" width="" alt="" v-if="getPlatformInfo('id') == 3">
      <p v-else-if="getPlatformInfo('id')" class="mt-3" style="color: white;text-align: center">{{
          getPlatformTitle()
        }}</p>
    </div>
    <div class="loginContainer flex-grow-1">
      <AuthForm @afterLogin="afterLogin"/>
    </div>
    <div class="footerArea text-center py-2">
      © copyright 2022 {{ getDomainPlatform() }} All Rights Reserved
    </div>
  </div>
</template>
<script>
import AuthForm from "@/components/Auth/AuthForm";

export default {
  name: 'AuthWindow',
  components: {AuthForm},
  data() {
    return {
      loginForm: {
        passwordType: 'password',
        email: null,
        password: null,
      }
    }
  },
  methods: {
    changePassType() {
      if (this.loginForm.passwordType == 'password') {
        this.loginForm.passwordType = 'text'
      } else {
        this.loginForm.passwordType = 'password'
      }
    },
    afterLogin() {
      this.$emit('afterLogin')
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px ) and (max-width: 600px) {
  .loginBox {
    width: 90% !important;
    margin: 10px auto !important;
  }

  .loginArea {
    overflow: auto !important;
  }

  .headerBanner {
    height: 30px !important;
  }
}

.headerBanner {
  background: #3cbe8b;
  height: 55px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.headerBanner img {
  margin: 0px auto;
}

.loginArea {
  height: 100%;
  overflow: hidden;
}

.loginContainer {
  background: url("../../assets/image/webp/login/Login.jpg") no-repeat center;
  background-size: cover;
}

.footerArea {
  color: #6d6d6d;
  font-size: 12px;
  direction: ltr
}


</style>